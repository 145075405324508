import React from 'react';

// Import styles 
import styles from './Services.module.css';

// Import components
import HeadingOne from '../components/HeadingOne';
import HeadingTwo from '../components/HeadingTwo';
import ParagraphOne from '../components/ParagraphOne';
import BaseButton from '../components/BaseButton';

function Services() {

    function handleContactUsClick() {
        window.location.href = '/contact-us';
    }

    return ( 
        <div className={styles.servicesPage}>
            <HeadingOne additionalClassNames={styles.servicesPageTitle}>SERVICES</HeadingOne>
            <div className={styles.servicesContainer}>
                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>OIL CHANGE</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>Keep your engine running smoothly with a professional oil change.</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>TIRE SERVICES</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>Ensure your tires are in top condition with our comprehensive tire services.</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>NEW TIRES</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>Upgrade your ride with a selection of high-quality new tires. <a className={styles.newTiresLink} href='/'>See here</a></ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>HOURLY TRUCK INSPECTION</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>Get a thorough checkup of your vehicle in just 2 hours.</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>BREAKS</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>Trust us to maintain and repair your brake system for safety and reliability.</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>PADS</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>Replace worn brake pads to ensure optimal stopping power</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>ROTORS</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>We provide expert rotor maintenance and replacement for smooth braking.</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>CLUTCHES</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>Rely on us for clutch repairs and replacements to keep your vehicle shifting smoothly.</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>MOTORS</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>Our motor services cover everything from repairs to complete engine overhauls.</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>PAINTING AND DENT FIXES</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>We paint and fix dents to restore them to their original look.</ParagraphOne>
                </div>

                <div className={styles.serviceItem}>
                    <HeadingTwo additionalClassNames={styles.serviceItemTitle}>MUCH MORE</HeadingTwo>
                    <ParagraphOne additionalClasses={styles.serviceItemDescription}>If the service you require is not listed above, please contact us to confirm availability.</ParagraphOne>
                </div>

                <div className={`${styles.serviceItem} ${styles.serviceItemCTAButton}`}>
                    <BaseButton additionalClassNames={styles.servicesCTAButton} text={'CONTACT US'} onClick={handleContactUsClick}/>
                </div>
            </div>
        </div>
    );
}

export default Services;