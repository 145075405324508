import React from 'react';

// Import styles
import styles from './AboutUs.module.css';

// Import components
import HeadingOne from '../components/HeadingOne';
import ParagraphOne from '../components/ParagraphOne';
import DarkButton from '../components/DarkButton';

// Import media
import LeftImage from '../assets/open_trucks.jpeg';
import RightImage from '../assets/two_crew_members_fixing_truck.jpeg';

function AboutUs() {
    function handleContactUsClick() {
        window.location.href = '/contact-us';
    }

    return ( 
        <div className={styles.aboutUsPage}>
            <div className={styles.aboutUsPageContentContainer}>
                <div className={styles.aboutUsLeftContentContainer}>
                    <HeadingOne additionalClassNames={styles.aboutUsTitle}>ABOUT US</HeadingOne>

                    <ParagraphOne>
                        Welcome to C&R Truck and Trailer Diesel Repair, your one-stop shop for all things trucks. We specialize in truck repair, parts, and sales of trucking vehicles. With years of experience under our belt, we are true experts in keeping your trucks on the road and running smoothly.
                    </ParagraphOne>

                    <ParagraphOne>
                        At C&R, we take pride in our work and are committed to providing top-notch service for all your trucking needs. Whether you’re looking to repair your truck, find the right parts, or purchase a new vehicle, we’ve got you covered.
                    </ParagraphOne>

                    <ParagraphOne>
                        For more information or to speak with one of our experts, give us a call today. We’re here to help.
                    </ParagraphOne>
                </div>

                <div className={styles.aboutUsRightContentContainer}>
                    <div className={styles.buttonContainer}>
                        <DarkButton text={"CONTACT US"} onClick={handleContactUsClick}/>
                    </div>
                </div>
            </div>

            <div className={styles.aboutUsPageImagesContainer}>
                <div className={styles.leftImageContainer}>
                    <img src={LeftImage} alt='Two trucks with the hoods open.'/>
                </div>

                <div className={styles.rightImageContainer}>
                    <img src={RightImage} alt='Two crew members fixing a truck.'/>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;