import React, { useState } from 'react';
import { Link } from "react-router-dom";

// Import styles
import styles from './NavbarMobile.module.css';

// Import media
import HamburgerIcon from '../../assets/icons/white_hamburger_icon.png';
import CloseIcon from '../../assets/icons/white_x_close_icon.png';
import Logo from '../../assets/logo.png';

function NavbarMobile({additionalClassNames}) {
    const [getOptionsVisible, setOptionsVisible] = useState(false);

    // Open when someone clicks on the span element
    function handleOpenClick() {
        if (getOptionsVisible) {
            // Hide options
            document.getElementById("mobileNavbarOptionContainer").style.display = "none";

            // Show hamburger and hide close icon
            document.getElementById("hamburgerIcon").style.display = "inline-block";
            document.getElementById("closeIcon").style.display = "none";

            setOptionsVisible(false);

        } else {
            // Show options
            document.getElementById("mobileNavbarOptionContainer").style.display = "inline-block";

            // Show close icon and hide hamburger icon
            document.getElementById("hamburgerIcon").style.display = "none";
            document.getElementById("closeIcon").style.display = "inline-block";

            setOptionsVisible(true);
        }
    }

    // Scroll to top of the page upon page selection
    const handleOptionSelection = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0
        });

        // Hide option container
        document.getElementById("mobileNavbarOptionContainer").style.display = "none";

        // Show hamburger and hide close icon
        document.getElementById("hamburgerIcon").style.display = "inline-block";
        document.getElementById("closeIcon").style.display = "none";

        setOptionsVisible(false);
    };

    return ( 
        <nav className={`${additionalClassNames} ${styles.navbarMobile}`}>
            <div className={styles.navbarMobileContent}>
                {/* Logo */}
                <img className={styles.logo} src={Logo} alt='Navigation bar logo'/>

                <img id='hamburgerIcon' className={styles.navBarIcon} src={HamburgerIcon} alt='Hamburger Icon (open menu)' onClick={handleOpenClick}/>
                <img id='closeIcon' className={`${styles.navBarIcon} ${styles.closeIcon}`} src={CloseIcon} alt='Close Icon (close menu)' onClick={handleOpenClick}/>
            </div>

            {/* Option container */}
            <div id='mobileNavbarOptionContainer' className={styles.optionContainer}>
                <Link className={styles.navLink} onClick={handleOptionSelection} to="/">HOME</Link>
                <Link className={styles.navLink} onClick={handleOptionSelection} to="/services">SERVICES</Link>
                <Link className={styles.navLink} onClick={handleOptionSelection} to="/inventory">INVENTORY</Link>
                <Link className={styles.navLink} onClick={handleOptionSelection} to="/parts">PARTS</Link>
                <Link className={styles.navLink} onClick={handleOptionSelection} to="/tires">TIRES</Link>
                <Link className={styles.navLink} onClick={handleOptionSelection} to="/contact-us">CONTACT US</Link>
                <Link className={styles.navLink} onClick={handleOptionSelection} to="/careers">CAREERS</Link>
                <Link className={styles.navLink} onClick={handleOptionSelection} to="/about-us">ABOUT</Link>
            </div>
        </nav>
    );
}

export default NavbarMobile;