import React from "react";
import styles from './DarkButton.module.css';

function DarkButton({additionalClassNames, text, onClick}) {
    return ( 
        <div className={`${additionalClassNames} ${styles.darkButton}`} onClick={onClick}>
            <p>{text}</p>
        </div>
     );
}

export default DarkButton;