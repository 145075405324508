import React from 'react';

// Import styles
import styles from './Tires.module.css';

// Import components
import HeadingOne from '../components/HeadingOne';
import HeadingTwo from '../components/HeadingTwo';
import BaseButton from '../components/BaseButton';

// Import Media
import TiresOne from '../assets/drive_tires.jpg';
import TiresTwo from '../assets/trailer_tires.jpeg';
import TiresThree from '../assets/steer_tires.jpeg';
import ParagraphOne from '../components/ParagraphOne';

function Tires() {
    function handleContactUsClick() {
        window.location.href = '/contact-us';
    }

    return ( 
        <div className={styles.tiresPage}>
            <HeadingOne additionalClassNames={styles.tiresTitle}>TIRES</HeadingOne>

            <div className={styles.tireTypesContainer}>
                <div className={styles.tireTypeItem}>
                    <div className={styles.tireImageContainer}>
                        <img src={TiresOne} alt='Drive tires'/>
                    </div>
                    <HeadingTwo additionalClassNames={styles.tireTypeTitle}>DRIVE</HeadingTwo>
                </div>

                <div className={styles.tireTypeItem}>
                    <div className={styles.tireImageContainer}>
                        <img src={TiresTwo} alt='Steer tires'/>
                    </div>
                    <HeadingTwo additionalClassNames={styles.tireTypeTitle}>STEER</HeadingTwo>
                </div>

                <div className={styles.tireTypeItem}>
                    <div className={styles.tireImageContainer}>
                        <img src={TiresThree} alt='Trailer tires'/>
                    </div>
                    <HeadingTwo additionalClassNames={styles.tireTypeTitle}>TRAILER</HeadingTwo>
                </div>
            </div>

            <div className={styles.tiresDescriptionContainer}>
                <div className={styles.tiresDescriptionLeft}>
                    <ParagraphOne additionalClasses={styles.tiresDescriptionParagraph}>At C&R Truck and Trailer Diesel Repair, we offer a wide selection of premium tires for all your trucking needs. Whether you're looking for durable drive tires, precision steer tires, or reliable trailer tires, we've got you covered. Our expert team ensures you get the right tire for your truck, ensuring safety, performance, and longevity on the road. Call today to learn more</ParagraphOne>
                </div>
                

                <div className={styles.tiresDescriptionRight}>
                    <BaseButton additionalClassNames={styles.tiresButton} text={'CONTACT US'} onClick={handleContactUsClick}/>
                </div>
            </div>
        </div>
    );
}

export default Tires;