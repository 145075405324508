import React, { useEffect, useState, useRef } from 'react';

// Import styles
import styles from './HeadingOne.module.css';

function HeadingOne({additionalClassNames, children, underline = true}) {
    const [isVisible, setIsVisible] = useState(false);
    const h1Ref = useRef(null);

    const checkVisibility = () => {
        if (h1Ref.current) {
            const rect = h1Ref.current.getBoundingClientRect();
            // Check if the <h1> is 50px above the bottom of the viewport
            if (rect.top < window.innerHeight - 150 && rect.bottom >= 0) {
                setIsVisible(true); 
            } else {
                // setIsVisible(false);
            }
        }
    };

    useEffect(() => {
        // Check visibility on mount
        checkVisibility();
    
        // Add scroll event listener
        window.addEventListener('scroll', checkVisibility);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', checkVisibility);
        };
    }, []);
    
    const underlineElement = () => {
        if (underline) {
            return <div className={styles.underline}></div>;
        } else {
            return null
        }
    }

    return ( 
        <h1 ref={h1Ref} className={isVisible ? `${additionalClassNames} ${styles.headingOne} ${styles.visible}` : `${additionalClassNames} ${styles.headingOne}`}>
            {underlineElement()}
            {children}
        </h1>
    );
}

export default HeadingOne;