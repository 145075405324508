import React, {useState} from 'react';

// Import styles
import styles from './InventoryItem.module.css';

// Import components
import SmallButton from '../SmallButton';

function InventoryItem({image, title, model, price, description}) {
    const [flipState, setFlipState] = useState(`${title}_front`);
    
    function handleSeeDetails(e) {
        const inventoryItem = document.getElementById(`inventoryItemInner_${title}`);
        if (flipState === `${title}_front`) {
            if (inventoryItem) {
                inventoryItem.style.transform = 'rotateY(180deg) translateZ(0)';
            }

            setFlipState(`${title}_back`)
        } else {
            if (inventoryItem) {
                inventoryItem.style.transform = 'rotateY(0deg) translateZ(0)';
            }

            setFlipState(`${title}_front`)
        }
    }

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })

    return ( 
        <div className={styles.inventoryItem}>
            <div id={`inventoryItemInner_${title}`} className={styles.inventoryItemInner}>
                {/* Front page */}
                <div className={styles.front}>
                    {/* Inventory item image */}
                    <div className={styles.imageContainer}>
                        <img src={image} alt='Inventory item'/>
                    </div>

                    {/* Inventory item title */}
                    <p className={styles.title}>{title}</p>

                    {/* Model */}
                    <p className={styles.model}>{model}</p>

                    {/* Price */}
                    <p className={styles.price}>Sale Price {USDollar.format(price)}</p>

                    {/* Description */}
                    <p className={styles.description}>{description}</p>

                    <SmallButton text={'DETAILS'} onClick={handleSeeDetails}/>
                </div>

                {/* Back page */}
                <div className={styles.back}>
                    <SmallButton text={'BACK'} onClick={handleSeeDetails}/>
                    <p>DETAILS GO HERE</p>
                </div>
            </div>
        </div>
    );
}

export default InventoryItem;