import React from 'react';

// Import styles
import styles from './InventoryListItem.module.css';

function InventoryListItem({additionalClassNames, id, image, title, model, price, description}) {
    const handleEditButtonPress = (e) => {
        const itemID = e.target.id;
        alert(`Edit button ${itemID} pressed!`)
    };

    return ( 
        <div className={styles.listItem}>
            <p>Title: {title}</p>
            <p>Inventory ID: {id}</p>
            <p>Model: {model}</p>
            <p>Price: {price}</p>
            <p>Description: {description}</p>

            <div id={`editButton_${id}`} className={styles.editButton} onClick={handleEditButtonPress}>
                Edit
            </div>
        </div>
    );
}

export default InventoryListItem;