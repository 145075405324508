import React from 'react';

// Import styles
import styles from './HeadingTwo.module.css';

function HeadingTwo({additionalClassNames, children}) {
    return ( 
        <h1 className={`${additionalClassNames} ${styles.headingTwo}`}>{children}</h1>
    );
}

export default HeadingTwo;