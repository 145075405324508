import React from 'react';

// Import styles
import styles from './Navbar.module.css';

// Import components
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

function Navbar() {
    return ( 
        <div className={styles.navBar}>
            <NavbarDesktop additionalClassNames={styles.desktopNavbar}/>

            <NavbarMobile additionalClassNames={styles.mobileNavbar}/>
        </div>
    );
}

export default Navbar;