import React from 'react';

// Import styles 
import styles from './FloatingCallIcon.module.css';

// Import media
import PhoneIcon from '../../assets/icons/phone_icon_one.webp';

function FloatingCallIcon() {

    const handlePhoneCall = () => {
        window.location.href = 'tel:+17345641341';
    };

    return ( 
        <div className={styles.floatingCallIcon} onClick={handlePhoneCall}>   
            <img src={PhoneIcon} alt='Phone icon'/>
        </div>
    );
}

export default FloatingCallIcon;