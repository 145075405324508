import React from 'react';

// Import styles
import styles from './Parts.module.css';

// Import components
import HeadingOne from '../components/HeadingOne';
import ParagraphOne from '../components/ParagraphOne';
import DarkButton from '../components/DarkButton';

// Import media
import PartsSectionSmallImage from '../assets/truck_in_road.jpg';

function Parts() {
    function handleContactUsClick() {
        window.location.href = '/contact-us';
    }

    return ( 
        <div className={styles.partsPage}>
            {/* ----------------------- PARTS BANNER ----------------------- */}
            <div className={styles.partsBanner}>
                <HeadingOne additionalClassNames={styles.partsBannerTitle} underline={false}>C&R TRUCK AND TRAILER PARTS DEPARTMENT</HeadingOne>

                {/* Shape divisor */}
                <div className={styles.custom_shape_divider_bottom_1726861097_desk}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" className={styles.shape_fill_desk}></path>
                    </svg>
                </div>
            </div>

            {/* ----------------------- PARTS PAGE CONTENT ----------------------- */}
            <div className={styles.partsPageContent}>
                <div className={styles.partsContentLeft}>
                    <ParagraphOne>
                        Even the most dependable equipment needs a new part now and then. We are proud to carry a wide selection of parts and accessories in the Dearborn area, so you can get the most out of your truck. If we don't have it in stock, our knowledgeable parts staff will find it for you.
                    </ParagraphOne>

                    <ParagraphOne>
                        We are committed to making sure you have the best experience with truck or trailer. Give us a call or stop by and talk to our qualified parts staff to help determine what parts and accessories you need to get the maximum value you demand from your purchase!
                    </ParagraphOne>

                    <ParagraphOne>
                        We feature great deals on commercial and heavy duty parts for our customers. Send us an email or call our number for the right part for your semi truck or trailer.
                    </ParagraphOne>

                    <ParagraphOne>
                        If you need help with repairs or installations, check out our Service Department.
                    </ParagraphOne>
                </div>

                <div className={styles.partsContentRight}>
                    <div className={styles.partsCircularImageContainer}>
                        <img src={PartsSectionSmallImage} alt='Truck on a road'/>
                    </div>

                    <DarkButton text={'CONTACT US'} onClick={handleContactUsClick}/>
                </div>
            </div>
        </div>
    );
}

export default Parts;