import React from "react";
import styles from './smallButton.module.css';

function SmallButton({additionalClassNames, text, onClick}) {
    return ( 
        <div className={`${additionalClassNames} ${styles.smallButton}`} onClick={onClick}>
            <p>{text}</p>
        </div>
     );
}

export default SmallButton;