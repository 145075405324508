import React, {useState} from 'react';
import { Link } from "react-router-dom";

// Import styles
import styles from './Home.module.css';

// Import components
import BaseButton from '../components/BaseButton';
import DarkButton from '../components/DarkButton';

import HeadingOne from '../components/HeadingOne';
import HeadingTwo from '../components/HeadingTwo';

import ParagraphOne from '../components/ParagraphOne';

// Import media
import PartsSectionSmallImage from '../assets/truck_in_road.jpg';

function Home() {
    const [height, setHeight] = useState(window.innerHeight);

    function scrollTopWindow() {
        window.scrollTo({
            top: 0
        });
    }

    function handlePartsClick() {
        window.location.href = '/parts';
    }

    function handleContactUsClick() {
        window.location.href = '/contact-us';
    }

    return ( 
        <div className={styles.homePage}>
            {/* ######################################### */}
            {/* ################# BANNER ################ */}
            {/* ######################################### */}
            <div className={styles.banner} style={{height: `${height}px`}}>
                <div className={styles.bannerContentContainer}>
                    <p className={styles.bannerTitlePrimary}>C&R</p>
                    <p className={styles.bannerTitleSecondary}>TRUCK AND TRAILER DIESEL REPAIR</p>
                    <BaseButton onClick={handleContactUsClick} additionalClassNames={styles.bannerButton} text={'GET QUOTE'} />
                </div>
            </div>

            {/* ############################################ */}
            {/* ################# SERVICES ################# */}
            {/* ############################################ */}
            <div className={styles.servicesSection}>
                <HeadingOne additionalClassNames={styles.servicesTitle}>SERVICES</HeadingOne>
                <div className={styles.servicesContainer}>
                    <div className={`${styles.serviceTypeCard} ${styles.serviceOneCard}`}>
                        <HeadingTwo>OIL CHANGE</HeadingTwo>
                    </div>

                    <div className={`${styles.serviceTypeCard} ${styles.serviceTwoCard}`}>
                        <HeadingTwo>TIRE SERVICES</HeadingTwo>
                    </div>

                    <div className={`${styles.serviceTypeCard} ${styles.serviceThreeCard}`}>
                        <HeadingTwo>HOURLY TRUCK INSPECTION</HeadingTwo>
                    </div>

                    <div className={`${styles.serviceTypeCard} ${styles.serviceFourCard}`}>
                        <HeadingTwo>ENGINE REPAIR</HeadingTwo>
                    </div>
                </div>

                <Link className={styles.servicesCallToAction} to={'/services'} onClick={scrollTopWindow}>SEE MORE SERVICES</Link>
            </div>

            {/* ##################################################### */}
            {/* ################# INVENTORY SECTION ################# */}
            {/* ##################################################### */}
            <div className={styles.inventorySection}>
                <HeadingOne additionalClassNames={styles.newInventoryTitle} underline={false}>NEW INVENTORY</HeadingOne>
                <div className={styles.ctaContainer}>
                    <Link className={styles.inventoryCallToAction} to={'/inventory'} onClick={scrollTopWindow}>CHECK INVENTORY</Link>
                </div>

                <div className={styles.newInventoryContainer}>
                    <a href='/'>
                        <div className={`${styles.inventoryItem} ${styles.inventoryItemOne}`}>

                        </div>
                    </a>

                    <a href='/'>
                        <div className={`${styles.inventoryItem} ${styles.inventoryItemTwo}`}>
                            
                        </div>
                    </a>

                    <a href='/'>
                        <div className={`${styles.inventoryItem} ${styles.inventoryItemThree}`}>
                            
                        </div>
                    </a>
                </div>
                
            </div>

            {/* ##################################################### */}
            {/* ################# NEW PARTS SECTION ################# */}
            {/* ##################################################### */}
            <div className={styles.newPartsSection}>
                <div className={styles.newPartsSectionImage}>

                </div>

                <div className={styles.newPartsSectionContent}>
                    <HeadingOne additionalClassNames={styles.partsTitle}>NEED PARTS?</HeadingOne>

                    <ParagraphOne additionalClasses={styles.partsParagraph}>We've got you covered. From engines and brakes to suspension kits and more, we have everything you need to keep your truck in top shape.</ParagraphOne>

                    <ParagraphOne additionalClasses={styles.partsParagraph}>Whether you're fixing a problem or upgrading your ride, our wide selection of quality parts will help you get the job done right. If you're not sure what you need, our team is here to help you find the right part for your truck.</ParagraphOne>

                    <ParagraphOne additionalClasses={styles.partsParagraph}>Don't let missing parts slow you down—give us a call and get back on the road.</ParagraphOne>

                    <BaseButton text={'PARTS'} onClick={handlePartsClick}/>

                    <div className={styles.partsCircularImageContainer}>
                        <img src={PartsSectionSmallImage} alt='Truck on a road'/>
                    </div>
                </div>
            </div>

            {/* #################################################### */}
            {/* ################# ABOUT US SECTION ################# */}
            {/* #################################################### */}
            <div className={styles.aboutUsSection}>
                <div className={styles.aboutUsSectionContent}>
                    <HeadingOne additionalClassNames={styles.aboutUsTitle}>ABOUT US</HeadingOne>

                    <ParagraphOne additionalClasses={styles.aboutUsParagraph}>At C&R TRUCK AND TRAILER DIESEL REPAIR, we're proud to serve the Westland, Michigan community with top-notch automotive repair and maintenance services. With years of experience under our belt, our certified technicians are committed to providing reliable, honest, and efficient work for every vehicle that rolls into our shop. Whether it's routine maintenance or complex repairs, we treat every car like it's our own, ensuring safety and satisfaction for all our customers. Visit us today, and experience the difference of hometown service with a professional touch.</ParagraphOne>

                    <DarkButton text={'CONTACT US'} onClick={handleContactUsClick}/>
                </div>

                <div className={styles.aboutUsSectionImage}>

                </div>
            </div>

        </div>
    );
}

export default Home;