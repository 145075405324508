import React from 'react';
import { Link } from "react-router-dom";

// Import styles
import styles from './NavbarDesktop.module.css'

// Import media
import Logo from '../../assets/logo.png';

// Import Components
import BaseButton from '../BaseButton';

function NavbarDesktop({additionalClassNames}) {
    // Scroll to top of the page upon page selection
    const handleOptionSelection = (e) => {
        // const optionElementId = e.target.id;
        // document.getElementById(optionElementId).style.fontWeight = '600';

        window.scrollTo({
            top: 0
        });
    };

    // Scroll to top of the page upon page selection and open email client
    const handleClickQuote = () => {
        window.location.href = "mailto:cnrtruckrepair@gmail.com";
    };

    return ( 
        <nav className={`${additionalClassNames} ${styles.navbarDesktop}`}>
            <div className={styles.navbarDesktopContent}>
                {/* Options container */}
                <div className={styles.optionsContainer}>
                    <Link id='home_option' className={styles.navLink} onClick={handleOptionSelection} to="/">HOME</Link>
                    <Link id='services_option' className={styles.navLink} onClick={handleOptionSelection} to="/services">SERVICES</Link>
                    <Link id='inventory_option' className={styles.navLink} onClick={handleOptionSelection} to="/inventory">INVENTORY</Link>
                    <Link id='parts_option' className={styles.navLink} onClick={handleOptionSelection} to="/parts">PARTS</Link>
                    <Link id='tires_option' className={styles.navLink} onClick={handleOptionSelection} to="/tires">TIRES</Link>
                    <Link id='contact_option' className={styles.navLink} onClick={handleOptionSelection} to="/contact-us">CONTACT US</Link>
                    <Link id='careers_option' className={styles.navLink} onClick={handleOptionSelection} to="/careers">CAREERS</Link>
                    <Link id='about_option' className={styles.navLink} onClick={handleOptionSelection} to="/about-us">ABOUT</Link>
                </div>
                
                {/* Logo */}
                <img className={styles.logo} src={Logo} alt='Navigation bar logo'/>

                {/* CAT Button */}
                <div className={styles.navbarButtonContainer}>
                    <BaseButton additionalClassNames={styles.navbarButton} text={"GET QUOTE"} onClick={handleClickQuote}/>
                </div>

            </div>
        </nav>
    );
}

export default NavbarDesktop;