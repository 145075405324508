import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import styles
import styles from './AdminDashboard.module.css';

// Import components
import HeadingOne from '../components/HeadingOne';
import InventoryListItem from '../components/InventoryListItem';

function AdminDashboard() {
  const navigate = useNavigate();

  // #############################################
  // ### Make sure that the admin is logged in ###
  // #############################################
  useEffect(() => {
    // Verify that the token exists and if it's still valid
    const verifyToken = async () => {
      const token = localStorage.getItem('token');

      try {
        // Make backend API call to verify token
        const response = await fetch(`http://localhost:8000/auth/verify-token/${token}`);

        // Unsuccessful authentication
        if (!response.ok) {
          throw new Error('Token verification failed');
        }
      } catch (error) {
        localStorage.removeItem('token'); // Remove token
        navigate('/admin/login'); // Take back to login page
      }
    };

    verifyToken();
  }, [navigate]);

  // #####################################
  // ### Fetches the current Inventory ### 
  // #####################################
  const [inventoryItems, setInventoryItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch('http://127.0.0.1:8000/inventory/');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setInventoryItems(data);
      } catch (error) {
        alert('There has been an error fetching the inventory.');
      } 
    };

    fetchItems();
  }, []);

  // ####################################################
  // ### Submits a new inventory item to the database ###
  // ####################################################
  const [imageFile, setImageFile] = useState(null);
  const [imageFileURL, setImageFileURL] = useState('');
  const [title, setTitle] = useState('');
  const [model, setModel] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Validate that the form contains necessary information
  const validateForm = () => {
    if (!title || !model || !price) {
      setError('Please enter necessary information.');
      return false;
    }
    setError('');
    return true;
  };

  // Submit image event handler
  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Get the file submitted
    if (file) {
      const url = URL.createObjectURL(file);
      setImageFile(file);
      setImageFileURL(url);
    }
  };

  // Login form submission handler
  const handleSubmit = async (event) => {
    // ???
    event.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    setLoading(true);

    // Define query parameter 
    // const inventoryItemData = { title: title, model: model, price: parseFloat(price), description: description, image: imageFile};
    const formData = new FormData();
    formData.append('title', title);
    formData.append('model', model);
    formData.append('price', parseFloat(price));
    formData.append('description', description);
    formData.append('image', imageFile);

    try {
      // Make backend API call to add inventory item
      const response = await fetch('http://localhost:8000/inventory/item', {
        method: 'POST',
        body: formData,
      });

      setLoading(false);

      if (response.ok) {
        // If the inventory item was successfully added, show success alert
        alert('Inventory item successfully added.')
        window.location.reload();
      } else {
        alert('Failed to add item to inventory!')
        const errorData = await response.json();
        setError(errorData.detail || 'Failed to add inventory item!');
      }
    } catch (error) {
      setLoading(false);
      setError('An error occurred. Please try again later.');
    }
  };

  return ( 
    <div className={styles.adminDashboard}>
      <HeadingOne additionalClassNames={styles.pageTitle}>Admin Portal</HeadingOne>

      {/* ----------------------------------------- */}
      {/* --- Create New Inventory Item Section --- */}
      {/* ----------------------------------------- */}
      <div className={styles.itemCreationSection}>
        <h2>Create New Inventory Item</h2>
        <form onSubmit={handleSubmit}>
          {/* Picture */}
          <div>
            {/* Show a preview of the submitted image */}
            {imageFileURL && <img className={styles.uploadedImage} src={imageFileURL} alt="Uploaded"/>}

            <label>Picutre:</label>
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
            />
          </div>

          {/* Title */}
          <div>
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          {/* Model */}
          <div>
            <label>Model:</label>
            <input
              type="text"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            />
          </div>

          {/* Price */}
          <div>
            <label>Price:</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          {/* Description */}
          <div>
            <label>Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* Submit button */}
          <button type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>

          {/* Error field */}
          {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
        </form>
      </div>

      {/* -------------------------------- */}
      {/* --- Manage Inventory Section --- */}
      {/* -------------------------------- */}
      <div className={styles.manageInventorySection}>
        <h2>Manage Inventory</h2>

        {inventoryItems.map(item => (
          <InventoryListItem id={item.id} title={item.title} model={item.model} price={item.price} description={item.description}/>
        ))}
      </div>
    </div>
  );
}

export default AdminDashboard;
