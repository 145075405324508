import React, { useState } from 'react';

// Import styles 
import styles from './BasicForm.module.css';

function BasicForm({submitHandler}) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [message, setMessage] = useState('');

    function handleFirstNameChange(e) {
        const name = e.target.value;
        setFirstName(name);
    }

    function handleLastNameChange(e) {
        const name = e.target.value;
        setLastName(name);
    }

    function handleEmailChange(e) {
        const emailVal = e.target.value;
        setEmail(emailVal);
    }

    function handlePhoneNumberChange(e) {
        const number = e.target.value;
        setPhoneNumber(number);
    }

    function handleMessageChange(e) {
        const message = e.target.value;
        setMessage(message);
    }

    return ( 
        <form className={styles.form} onSubmit={submitHandler}>
            {/* Name */}
            <div className={styles.nameContainer}>
                <div>
                    {/* First Name  */}
                    <label htmlFor="firstName">First name</label>
                    <input className={styles.fullSizeInput} type="text" name="firstName" id="firstName" onChange={handleFirstNameChange} value={firstName} required></input>
                </div>
                <div>
                    {/* Last Name */}
                    <label htmlFor="lastName">Last name</label>
                    <input className={styles.fullSizeInput} type="text" name="lastName" id="lastName" onChange={handleLastNameChange} value={lastName} required></input>
                </div>
            </div>

            {/* Email */}
            <div className={styles.inputSection}>
                <label htmlFor="email">Email</label>
                <input className={styles.fullSizeInput} type="text" name="email" id="email" onChange={handleEmailChange} value={email} required></input>
            </div>

            {/* Phone */}
            <div className={styles.inputSection}>
                <label htmlFor="phone">Phone</label>
                <input className={styles.fullSizeInput} type="tel" name="phone" id="phone" maxlength="12"  title="Ten digits code" onChange={handlePhoneNumberChange} value={phoneNumber} required></input>
            </div>

            {/* Message area */}
            <div className={styles.inputSection}>
                <label for="message">Message</label>
                <textarea className={styles.messageTextArea} id="message" name="message" rows="4" cols="50" onChange={handleMessageChange} value={message}></textarea>
            </div>

            {/* Submit button */}
            <div className={styles.sendButtonContainer}>
                <input className={styles.sendButton} type='submit' value="SEND"/>
            </div>
        </form>    
    );
}

export default BasicForm;