import { Route, Routes } from 'react-router-dom';
import './App.css';

// Import pages
import Home from './pages/Home';
import Services from './pages/Services';
import Inventory from './pages/Inventory';
import Parts from './pages/Parts';
import Tires from './pages/Tires';
import ContactUs from './pages/ContactUs';
import Careers from './pages/Careers';
import AboutUs from './pages/AboutUs';

import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';

// Import Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FloatingCallIcon from './components/FloatingCallIcon';

function App() {
  return (
    <div className="App">
      <Navbar />

      <FloatingCallIcon />

      <Routes>
         <Route path='/' element={<Home/>} />
         <Route path='/services' element={<Services/>} />
         <Route path='/inventory' element={<Inventory />} />
         <Route path='/parts' element={<Parts/>} />
         <Route path='/tires' element={<Tires/>} />
         <Route path='/contact-us' element={<ContactUs/>} />
         <Route path='/careers' element={<Careers />} />
         <Route path='/about-us' element={<AboutUs />} />

         {/* Admin routes */}
         <Route path='/admin/login' element={<Login />} />
         <Route path='/admin/dashboard' element={<AdminDashboard />} />
      </Routes>

       <Footer />
    </div>
  );
}

export default App;
