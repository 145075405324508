import React from 'react';
import emailjs from 'emailjs-com';

// Import styles
import styles from './Careers.module.css';

// Import components
import HeadingOne from '../components/HeadingOne';
import BasicFormTwo from '../components/BasicFormTwo';

// Import media
import CareersImage from '../assets/crew_member.jpeg';

function Careers() {
    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_sddm4bf', 'template_t312zcl', e.target, 'obDGwJ3RZvRcMQQc1')
            .then((result) => {
                alert("Application submitted successfully! We'll be in touch with you soon.");
                window.location.reload()
            }, (error) => {
                alert('There has been an error while submitting the application. Try again.');
                console.log(error.text);
            });
    }

    return ( 
        <div className={styles.careersPage}>
            <div className={styles.careersPageContentContainer}>
                <div className={styles.careersPageLeftContainer}>
                    <div className={styles.imageContainer}>
                        <img src={CareersImage} alt='Crew member potrait'/>
                    </div>
                </div>

                <div className={styles.careersPageRightContainer}>
                    <HeadingOne additionalClassNames={styles.rightSectionTitle} underline={false}>LET'S WORK TOGETHER</HeadingOne>

                    <div className={styles.imageContainerMobile}>
                        <img src={CareersImage} alt='Crew member potrait'/>
                    </div>

                    <div className={styles.formContainer}>
                        <BasicFormTwo submitHandler={sendEmail}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Careers;