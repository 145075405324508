import React from 'react';
import emailjs from 'emailjs-com';

// Import styles
import styles from './ContactUs.module.css';

// Import components
import HeadingOne from '../components/HeadingOne';
import ParagraphOne from '../components/ParagraphOne';
import BasicForm from '../components/BasicForm';

function ContactUs() {

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_sddm4bf', 'template_nqgbivn', e.target, 'obDGwJ3RZvRcMQQc1')
            .then((result) => {
                alert("Message sent successfully! We'll be in touch with you soon.");
                window.location.reload()
            }, (error) => {
                alert('There has been an error while sending the message. Try again.');
                console.log(error.text);
            });
    }

    return ( 
        <div className={styles.contactUsPage}>
            <HeadingOne additionalClassNames={styles.contactUsTitle}>CONTACT US</HeadingOne>

            <ParagraphOne additionalClasses={styles.contactUsDescription}>We're Here to Help! Whether you need routine maintenance, urgent repairs, or have questions about your vehicle's performance, our team of experienced mechanics is ready to assist you. Your satisfaction and safety are our top priorities, so don't hesitate to get in touch—no job is too big or too small!</ParagraphOne>

            <div className={styles.formContainer}>
                <BasicForm submitHandler={sendEmail}/>
            </div>
        </div>
     );
}

export default ContactUs;