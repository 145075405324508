import React, { useEffect, useState } from 'react';

// Import styles 
import styles from './Inventory.module.css';

// Import components
import InventoryItem from '../components/InventoryItem';
import HeadingOne from '../components/HeadingOne';
import ParagraphOne from '../components/ParagraphOne';

// Import media
import InventoryItemImageOne from '../assets/test/gmc_sierra.avif';
// import InventoryItemImageTwo from '../assets/gears.jpg';
// import InventoryItemImageThree from '../assets/open_trucks.jpeg';

function Inventory() {

    // #####################################
    // ### Fetches the current Inventory ### 
    // #####################################
    const [inventoryItems, setInventoryItems] = useState([]);

    useEffect(() => {
    const fetchItems = async () => {
        try {
            const response = await fetch('http://127.0.0.1:8000/inventory/');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setInventoryItems(data);
        } catch (error) {
            alert('Inventory under maintenance. Please contact C&R Truck and Trailer Repair for any inquiries.');
        } 
    };

    fetchItems();
    }, []);

    console.log(inventoryItems[0])
    

    return ( 
        <div className={styles.inventoryPage}>
            <HeadingOne additionalClassNames={styles.inventoryTitle}>INVENTORY</HeadingOne>
            <ParagraphOne additionalClasses={styles.introParagraph}>Welcome to our Diesel Truck Inventory page! Here at C&R Truck and Trailer Diesel Repair, we specialize in buying, selling, and repairing high-quality diesel trucks. Whether you're looking for a reliable workhorse or a restoration project, our diverse selection has something for everyone. Browse our current inventory to find the perfect truck that meets your needs, and don’t hesitate to reach out with any questions. Let us help you hit the road in style and performance!</ParagraphOne>

            <div className={styles.inventoryItemsContainer}>
                {inventoryItems.map(item => (
                    <InventoryItem image={InventoryItemImageOne} title={item.title} model={item.model} price={item.price} description={item.description}/>
                ))}
            </div>
        </div>
    );
}

export default Inventory;